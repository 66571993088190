import axios from 'axios';
import API_URL from '../backend'
import authHeader from './auth-header.service';
class DatosWeb{
    getDatos(){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/admin/datosWeb/datos',
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
    actualizarDatos(datos){
        const axiosRequest = {
            method: 'post',
            url: API_URL+'/admin/datosWeb/actualizar',
            data:datos,
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
}
export default new DatosWeb();