<template lang="html">
  <section class="v-datos-web">
    <h3>Configuración datos de empresa</h3>
    <form
      @submit.prevent="actualizarDatosWeb"
      class="p-fluid d-flex flex-wrap ml-auto mr-auto p-1 p-lg-2 col-12 col-lg-8 justify-content-center align-content-between"
    >
      <span class="p-input-icon-left mt-4 p-col-12">
        <i class="pi pi-phone ml-1 mr-1"></i>
        <InputText
          v-tooltip="'Teléfono'"
          id="xtelefono"
          name="xtelefono"
          type="telf"
          v-model="datos.xtelefono"
          placeholder="Telefono"
        />
        <label for="xtelefono" class="d-none">Teléfono</label>
      </span>
      <span class="p-input-icon-left mt-4 p-col-12">
        <i class="far fa-envelope ml-1 mr-1"></i>
        <InputText
          v-tooltip="'Email'"
          id="mail"
          name="xemail"
          type="xemail"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          v-model="datos.xemail"
          placeholder="Email"
        />
        <label for="xemail" class="d-none">Email</label>
      </span>
      <span class="p-input-icon-left mt-4 p-col-12">
        <i class="pi pi-linkedin ml-1 mr-1"></i>
        <InputText
          v-tooltip="'Linkedin'"
          id="xlinkedin"
          name="xlinkedin"
          type="url"
          v-model="datos.xlinkedin"
          placeholder="URL Linkedin"
        />
        <label for="xlinkedin" class="d-none">Linkedin</label>
      </span>
      <span class="p-input-icon-left mt-4 p-col-12">
        <i class="pi pi-twitter ml-1 mr-1"></i>
        <InputText
          v-tooltip="'Twitter'"
          id="xtwitter"
          name="xtwitter"
          type="url"
          v-model="datos.xtwitter"
          placeholder="URL Twitter"
        />
        <label for="xtwitter" class="d-none">Twitter</label>
      </span>
      
      <span class="p-input-icon-left mt-4 p-col-12">
        <i class="far fa-envelope ml-1 mr-1"></i>
        <InputText
          v-tooltip="'Delegado RGPD (email)'"
          id="xdelegadoRGPD"
          name="xdelegadoRGPD"
          type="text"
          v-model="datos.xdelegadoRGPD"
          placeholder="Delegado RGPD (email)"
        />
        <label for="xdelegadoRGPD" class="d-none">Delegado RGPD</label>
      </span>
      <span class="p-input-icon-left mt-4 p-col-12">
        <i class="far fa-envelope ml-1 mr-1"></i>
        <InputText
          v-tooltip="'Email reclamación de derechos'"
          id="xemail_derechos"
          name="xemail_derechos"
          type="text"
          v-model="datos.xemail_derechos"
          placeholder="Email reclamación de derechos"
        />
        <label for="xemail_derechos" class="d-none">Email reclamación de derechos</label>
      </span>
      <span class="p-input-icon-left mt-4 p-col-12">
        <i class="fa fa-scroll"></i>
        <InputText
          v-tooltip="'CIF'"
          id="xcif"
          name="xcif"
          type="text"
          v-model="datos.xcif"
          placeholder="CIF"
        />
        <label for="xcif" class="d-none">CIF</label>
      </span>
      <span class="p-input-icon-left mt-4 p-col-12">
        <i class="fa fa-scroll"></i>
        <InputText
          v-tooltip="'Inscripción mercantil'"
          id="xinscripcion_mercantil"
          name="xinscripcion_mercantil"
          type="text"
          v-model="datos.xinscripcion_mercantil"
          placeholder="Inscripción mercantil"
        />
        <label for="xinscripcion_mercantil" class="d-none">Inscripción mercantil</label>
      </span>
      <span class="p-input-icon-left mt-4 p-col-12">
        <i class="fas fa-building"></i>
        <InputText
          v-tooltip="'Sede social'"
          id="xsede_social"
          name="xsede_social"
          type="text"
          v-model="datos.xsede_social"
          placeholder="Sede social"
        />
        <label for="xsede_social" class="d-none">Sede social</label>
      </span>
      <span class="p-input-icon-left mt-4 p-col-12">
        <i class="fas fa-building"></i>
        <InputText
          v-tooltip="'Sede social 2'"
          id="xsede_social2"
          name="xsede_social2"
          type="text"
          v-model="datos.xsede_social2"
          placeholder="Sede social 2"
        />
        <label for="xsede_social2" class="d-none">Sede social 2</label>
      </span>
      <span class="p-input-icon-left mt-4 p-col-12">
        <i class="fas fa-building"></i>
        <InputText
          v-tooltip="'Sede social 3'"
          id="xsede_social3"
          name="xsede_social3"
          type="text"
          v-model="datos.xsede_social3"
          placeholder="Sede social 3"
        />
        <label for="xsede_social2" class="d-none">Sede social 3</label>
      </span>
      <vs-card type="4" class="col-12 d-flex justify-content-center">
        <template #img>
          <img
            v-tooltip="'Logo'"
            :src="datos.xlogo"
            @error="imagenError($event, datos)"
            @click="uploadImg"
            class="product-image"
            style="max-height: 300px"
            alt=""
          />
        </template>
        <template #interactions>
          <vs-button icon floating type="button" @click="uploadImg">
            <i class="pi pi-images"></i>
          </vs-button>
        </template>
      </vs-card>
      <input
        type="file"
        hidden
        accept="image/*"
        size="2000000"
        @change="cambiarImg"
        ref="uploadImg"
      />
      <div class="col-8">
        <vs-button border type="submit" block>
          <i class="pi pi-replay mr-2"></i
          ><span class="text-lg">Actualizar datos</span>
        </vs-button>
      </div>
    </form>
  </section>
</template>

<script lang="js">
import DatosWeb from '../../services/datosWeb.service'
import API_URL from"../../backend.js"
  export default  {
    name: 'v-datos-web',
    props: [],
    mounted () {
      DatosWeb.getDatos().then((response)=>{
        this.datos = response.data[0];
        console.log(this.datos)
        this.datos.xlogo = API_URL+this.datos.xlogo;
      })
    },
    data () {
      return {
        datos:{}
      }
    },
    methods: {
      actualizarDatosWeb(){
        const formData = new FormData();
        formData.append("xtelefono", this.datos.xtelefono);
        formData.append("xemail", this.datos.xemail);
        formData.append("xlogo", this.datos.xlogo);
        formData.append("xlinkedin", this.datos.xlinkedin);
        formData.append("xtwitter", this.datos.xtwitter);
        formData.append("xcif", this.datos.xcif);
        formData.append("xdelegadoRGPD", this.datos.xdelegadoRGPD);
        formData.append("xemail_derechos", this.datos.xemail_derechos);
        formData.append("xinscripcion_mercantil", this.datos.xinscripcion_mercantil);
        formData.append("xsede_social", this.datos.xsede_social);
        formData.append("xsede_social2", this.datos.xsede_social2);
        formData.append("xsede_social3", this.datos.xsede_social3);
        formData.append("xweb", this.datos.xweb);
        formData.append("subirImg", this.datos.subirImg);


        DatosWeb.actualizarDatos(formData).then((response)=>{
          this.$toast.add({
            severity: response.data.success ? 'info' : 'error',
            title: response.data.success ? 'Actualizado' : 'Error',
            detail: response.data.description,
            life: response.data.success ? 3000 : null
          })
        }).catch((response)=>{
          this.$toast.add({
            severity:  'error',
            detail: response.data.description,
            life: null
          })
        })
      },
      imagenError(event){
        event.target.src = "/images/fondo_gris.jpg"
      },
      uploadImg(){
        this.$refs.uploadImg.click();
      },
      cambiarImg(){
        const [file] = this.$refs.uploadImg.files;
        if(file.size>10000000){//10MB
          this.$toast.add({
            severity:'error',
            summary:'Tamaño del archivo',
            detail:'La imagen no puede pesar más de 10MB',
            life:10000
          })
          return;
        }
        if(!file.type.includes("image/")){
          this.$toast.add({
            severity:'error',
            summary:'Tipo de archivo',
            detail:'El archivo debe ser de tipo imagen',
            life:10000
          })
          return;
        }

        this.datos.xlogo = URL.createObjectURL(file);
        this.datos.subirImg = file;


      },
    },
    computed: {

    }
}
</script>

<style scoped lang="scss">
.v-datos-web {
}
</style>
